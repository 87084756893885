
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("ember/index", function(){ return i("ember-source/ember/index.js");});
import "@ember-data/legacy-compat/-embroider-implicit-modules.js";
import "@ember-data/request/-embroider-implicit-modules.js";
import "@ember-data/request-utils/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@embroider/router/-embroider-implicit-modules.js";
import "@smile-io/ember-shopify-polaris/-embroider-implicit-modules.js";
import "@smile-io/polaris-icons/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-element-helper/-embroider-implicit-modules.js";
import "ember-keyboard/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-moment/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-simple-auth/-embroider-implicit-modules.js";
import "ember-sortable/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "api-proxy/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "@pollyjs/ember/-embroider-implicit-modules.js";
import "@ember/jquery/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-cli-sass/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-svg-jar/-embroider-implicit-modules.js";
import "@smile-io/ember-smile-polaris/-embroider-implicit-modules.js";
import "ember-cli-string-helpers/-embroider-implicit-modules.js";
import "ember-elsewhere/-embroider-implicit-modules.js";
import "@smile-io/ember-smile-core/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-inflector/-embroider-implicit-modules.js";
import "active-model-adapter/-embroider-implicit-modules.js";
import "ember-ajax/-embroider-implicit-modules.js";
import "ember-api-actions/-embroider-implicit-modules.js";
import "ember-changeset/-embroider-implicit-modules.js";
import "ember-changeset-validations/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-clipboard/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-cli-dependency-lint/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-autoprefixer/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-cli-stripe/-embroider-implicit-modules.js";
import "ember-config-service/-embroider-implicit-modules.js";
import "ember-copy/-embroider-implicit-modules.js";
import "ember-feature-flags/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-iframe-resizer-modifier/-embroider-implicit-modules.js";
import "ember-intercom-io/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-local-storage/-embroider-implicit-modules.js";
import "ember-metrics/-embroider-implicit-modules.js";
import "ember-route-helpers/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-steps/-embroider-implicit-modules.js";
import "ember-uploader/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
